$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    })
    ;
    // $("#profile-select").select2({
    //     ajax: {
    //         transport: function (params, success, failure) {
    //             var req = axios.get("/evento/autocomplete-ajax", {q: $("#profile-select").val()})
    //                 .then(function (response) {
    //                     success(response);
    //                     console.log(response.data);
    //                 })
    //                 .catch(function (error) {
    //                     alert(error);
    //                 });
    //         },
    //         processResults: function (data) {
    //             return {
    //                 results:  $.map(data, function (item) {
    //                     return {
    //                         text: item.name,
    //                         id: item.id
    //                     }
    //                 })
    //             };
    //         },
    //     },
    //     minimumInputLength: 2,
    //     placeholder: "Select a profile",
    //     allowClear: true,
    //     cache: true
    // });

    // const url = $('meta[name="base_url"]').attr('content');
    // $('#evento').select2({
    //     placeholder: 'Buscar Funcionário',
    //     language: {
    //         "noResults": function () {
    //             return "Nenhum resultado encontrado";
    //         },
    //         searching: function () {
    //             return "Buscando...";
    //         }
    //     },
    //     ajax: {
    //         url: url + '/autocomplete',
    //         data: function (params, evento) {
    //             var query = {
    //                 search: params.term,
    //             }
    //             return query;
    //         },
    //         processResults: function (data) {
    //             return {
    //                 results: $.map(data, function (item) {
    //                     return {
    //                         text: item.name,
    //                         id: item.id
    //                     }
    //                 })
    //             };
    //         },
    //         cache: true
    //     }
    // });

    $('#eventos').select2({
        placeholder: 'Buscar Funcionário',
        language: {
            noResults: function () {
                return "Nenhum resultado encontrado";
            },
            searching: function () {
                return "Buscando...";
            }
        },
        allowClear: true,
    }).on('change', function () {
        const presenca = $('#eventos').find(':selected').data('confirme');
        const key = $('#eventos').find(':selected').text();
        const ingressos = $('#eventos').find(':selected').data('ingressos');
        const campo3 = $('#eventos').find(':selected').data('campo3');
        const campo4 = $('#eventos').find(':selected').data('campo4');
        const campo5 = $('#eventos').find(':selected').data('campo5');
        const campo6 = $('#eventos').find(':selected').data('campo6');
        const campo7 = $('#eventos').find(':selected').data('campo7');
        const campo8 = $('#eventos').find(':selected').data('campo8');
        const convidados_restantes = $('#eventos').find(':selected').data('convidados');
        const restantes = $('#eventos').find(':selected').data('restantes');
        const url = $('meta[name=base_url]').attr('content');
        // if (presenca) {
        //     $('#btn-presenca').removeClass('btn-primary');
        //     $('#btn-presenca').addClass('btn-danger');
        //     $('#btn-presenca').text('Remover Presença');
        //     $('#form-presenca').attr('action', url + '/removePresenca');
        // } else {
        $('#btn-presenca').removeClass('btn-danger');
        $('#btn-presenca').addClass('btn-primary');
        $('#btn-presenca').text('Confirmar Presença');
        $('#form-presenca').attr('action', url + '/presenca');
        // }
        $('#funcionario').text('FUNCIONÁRIO(A):' + key);
        $('#ingressos').text('Ingressos: ' + ingressos);
        $('#campo_3').text(campo3);
        $('#campo_4').text(campo4);
        $('#ccampo5').text(campo5);
        $('#campo_6').text(campo6);
        $('#campo_7').text(campo7);
        $('#campo_8').text(campo8);
        // $('#ingressos_restantes').html('Retirar Agora <input type="number" path="note" name="ingressos_restantes" value="' +restantes+ '" min="1" max="' +restantes+ '" onKeyDown="return false"> Ingressos');

        $('#ingressos_restantes').html('<span style="padding-right: 3px;">Retirar Agora</span> <input id="ex6" type="text" name="ingressos_restantes" data-slider-min="1" data-slider-max="' +restantes+ '" data-slider-step="1" data-slider-value="' +restantes+ '"/>' +
            ' <span id="ex6CurrentSliderValLabel"> Ingressos: <span id="ex6SliderVal">' +restantes+ '</span></span><br>');
        $('#convidados_restantes').html('<span>Não Pagantes</span> <input id="ex7" type="text" name="convidados_restantes" data-slider-min="1" data-slider-max="' +convidados_restantes+ '" data-slider-step="1" data-slider-value="' +convidados_restantes+ '"/>' +
            ' <span id="ex7CurrentSliderValLabel"> Ingressos: <span id="ex7SliderVal">' +convidados_restantes+ '</span></span>');
        $("#ex6").slider();
        $("#ex6").on("slide", function(slideEvt) {
            $("#ex6SliderVal").text(slideEvt.value);
        });

        $("#ex7").slider();
        $("#ex7").on("slide", function(slideEvt) {
            $("#ex7SliderVal").text(slideEvt.value);
        });
        // axios.post('/evento.presenca', {
        //     id: key,
        //     presenca: presenca
        // })
        //     .then(function (response) {
        //         console.log(response);
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    });

    // $('#btn-presenca').on('click', function () {
    //     if ($("#btn-presenca").hasClass("btn-danger")) {
    //         alert('existe');
    //     }
    // });

    const confirmacao = $("#success-alert");
    if (confirmacao.length && confirmacao.hasClass('show')) {
        $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
            $("#success-alert").slideUp(500);
        });
    }
});

